<script>
export default {
	inheritAttrs: false,
	props: {
		src: String,
		referrerpolicy: String,
		alt: String,
		imgClass: [String, Object, Array],
		cover: Boolean,
		fill: Boolean,
		scaleDown: Boolean,
		backgroundColor: String,
		position: String,
		aspectRatio: [String, Number],
		original: Object,
		loader: Boolean,
	},
	data() {
		return { loading: false, error: false, iAspectRatio: null, srcUpdated: false }
	},
	computed: {
		cStyle() {
			let objectFit = this.cover ? 'cover' : this.fill ? 'fill' : this.scaleDown ? 'scale-down' : 'contain'
			return {
				objectFit,
				objectPosition: this.position || 'center',
				display: this.error ? 'none' : null,
				backgroundColor: this.backgroundColor || null,
				// opacity: this.loading  ? 0 : 1,
			}
		},
		cAspectRatio() {
			if (this.aspectRatio) return Number(this.aspectRatio)
			if (this.original) return Number(this.original.width) / Number(this.original.height)
			return this.iAspectRatio || 0
		},
		cWidth() {
			return this.$attrs.width || this.original?.width || null
		},
	},
	watch: {
		src() {
			this.init()
		},
	},
	methods: {
		init() {
			this.loading = !!this.src
			this.error = !this.src
		},
		onImgLoaded() {
			this.loading = false
			this.error = false
			this.$nextTick(() => {
				if (!this.$refs.img) return
				this.iAspectRatio = this.$refs.img.naturalWidth / this.$refs.img.naturalHeight
			})
		},
		onImgError() {
			this.error = true
			this.loading = false
		},
	},
	mounted() {
		this.init()
	},
}
</script>

<template>
	<RatioBox
		v-on="$listeners"
		v-bind="$attrs"
		:width="cWidth"
		:aspect-ratio="cAspectRatio"
		:style="{ border: error ? '1px solid #ccc' : null }"
		class="ratio-image"
	>
		<slot name="loader" v-if="loading && loader">
			<div class="w100 h100 d-flex justify-center align-center">
				<v-progress-linear color="primary" indeterminate style="width: 80%" />
			</div>
		</slot>
		<slot name="fallback" v-else-if="error">
			<div class="w100 h100 d-flex justify-center align-center">
				<v-icon>mdi-image-off</v-icon>
			</div>
		</slot>
		<img
			v-if="!error"
			class="ratio-image__image"
			:alt="alt"
			:src="src"
			:class="imgClass"
			:style="cStyle"
			:referrerpolicy="referrerpolicy"
			@load="onImgLoaded"
			@error="onImgError"
			ref="img"
		/>
		<div class="ratio-image__content">
			<slot />
		</div>
	</RatioBox>
</template>

<style lang="scss" scoped>
.ratio-image {
	&__image,
	&__content {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
	&__image {
		z-index: 0;
	}
	&__content {
		z-index: 1;
	}
}
</style>
