import Vue from 'vue'

function _cleanup(el, _onScrollFns) {
	let id = el.getAttribute('data-super-sticky-id')
	if (id) {
		el.style.position = ''
		el.style.top = ''
		let onScroll = _onScrollFns[id]
		if (onScroll) {
			window.removeEventListener('scroll', onScroll)
			_onScrollFns[id] = undefined
		}
	}
}

function _setup(el, id, _onScrollFns, options) {
	let last = window.scrollY - 1
	let status = null
	let fixpos = 0
	let fixtop = 0
	el.setAttribute('data-super-sticky-id', id)

	let getBaseTop = () => {
		return (options.baseTopEl ? options.baseTopEl.clientHeight : options.baseTop) || 0
	}

	let getBaseBottom = () => {
		return (options.baseBottomEl ? options.baseBottomEl.clientHeight : options.baseBottom) || 0
	}

	let bcr = el.getBoundingClientRect()
	let baseTop = getBaseTop()
	if (baseTop + bcr.height <= window.innerHeight) {
		el.style.position = 'sticky'
		el.style.top = String(baseTop) + 'px'
		return
	}

	const onScroll = () => {
		let down = scrollY > last
		last = scrollY
		let bcr = el.getBoundingClientRect()
		let baseTop = getBaseTop()
		let baseBottom = getBaseBottom()
		if (down) {
			if (status == 'top') {
				status = 'fix'
				fixpos = scrollY
				fixtop = bcr.top
			}
			if (bcr.bottom - window.innerHeight <= 0 && status != 'bottom') {
				el.style.position = 'sticky'
				el.style.top = window.innerHeight - bcr.height - baseBottom + 'px'
				status = 'bottom'
			}
		} else {
			if (status == 'bottom') {
				status = 'fix'
				fixpos = scrollY
				fixtop = bcr.top
			}
			if (bcr.top >= baseTop && status != 'top') {
				el.style.position = 'sticky'
				el.style.top = String(baseTop) + 'px'
				status = 'top'
			}
		}
		if (status == 'fix') {
			let newTop = Math.min(baseTop, fixtop - (scrollY - fixpos))
			el.style.top = newTop + 'px'
		}
	}

	_onScrollFns[id] = onScroll
	window.addEventListener('scroll', onScroll)
	onScroll()
}

export default (options) => {
	if (options.ssr) return

	const _onScrollFns = {}
	let _ids = 0

	Vue.directive('super-sticky', {
		bind: function (el, binding, vnode) {
			const value = binding.value
			if (!value) return _cleanup(el, _onScrollFns)

			const options = {}
			if (typeof value == 'object') {
				if ('top' in value) options.baseTop = Number(value.top || 0)
				else if ('topAsHeightOf' in value) options.baseTopEl = document.querySelector(value.topAsHeightOf)

				if ('bottom' in value) options.baseBottom = Number(value.bottom || 0)
				else if ('bottomAsHeightOf' in value)
					options.baseBottomEl = document.querySelector(value.bottomAsHeightOf)
			}

			_setup(el, ++_ids, _onScrollFns, options)
			const resizeObserver = new ResizeObserver(() => {
				setTimeout(() => {
					_cleanup(el, _onScrollFns)
					_setup(el, ++_ids, _onScrollFns, options)
				}, 100)
			})
			resizeObserver.observe(el)
		},
		unbind: function (el) {
			_cleanup(el, _onScrollFns)
		},
	})
}

