import gtagUtils from './googleAnalyticsUtils.js'
import fbPixelUtils from './fbPixelUtils.js'

export default (options) => {
	if (options.ssr) return

	const { eventer, store, srv, trackers, apis } = options
	const { shopApi } = apis

	const useFbpApi = srv('Analytics.useFbpApi')
	const facebookAction = async (eventName, data) => {
		const eventID = generateUUID(storeUser?.id)
		trackers.fbq('track', eventName, data, { eventID })
		if (!useFbpApi) return

		const pxEvData = { eventID, data, eventName }
		const storeUser = store.get('shop/user')
		if (storeUser) {
			const { accountEmail, fullPhone, id, facebookId } = storeUser
			pxEvData.user = { accountEmail, fullPhone, id, facebookId }
		}
		await shopApi.post({ url: '/fb-pixel-event', data: pxEvData }).catch(() => null)
	}

	// UPDATE USER ID ------------------------------------------
	let _currUserId = null
	const updateUser = (user) => {
		if (!user || _currUserId == user.id) return
		_currUserId = user.id

		trackers.gtag('set', { user_id: user.id })
		trackers.fbq('init', srv('Analytics.fbpId'), fbPixelUtils.normalizeUserData(user))
	}

	updateUser(store.get('shop/user'))
	store.watch(() => store.get('shop/user'), updateUser)

	// LISTENERS
	eventer.listen('product-list:view', ({ products, listId = null, listName = null }) => {
		const productsData = products.map((product, i) => ({
			product: product,
			variant: product.selectedVariant,
			index: 'index' in product ? product.index : i,
		}))
		trackers.gtag(
			'event',
			'view_item_list',
			gtagUtils.normalizeProductListData(productsData, listId, listName)
		)
	})

	eventer.listen('product:click', ({ product, variant, listId = null, listName = null, index = 0 }) => {
		const productsData = [{ product, variant: variant || product.selectedVariant, index }]
		trackers.gtag(
			'event',
			'select_item',
			gtagUtils.normalizeProductListData(productsData, listId, listName)
		)
	})

	eventer.listen('product:view', ({ product, variant }) => {
		let productsData = [{ product, variant }]
		trackers.gtag('event', 'view_item', gtagUtils.normalizeProductListData(productsData))

		const data = fbPixelUtils.normalizeProductData(product, variant)
		facebookAction('ViewContent', data)
	})

	eventer.listen('user:signup', ({ loginMethod, user }) => {
		trackers.gtag('event', 'sign_up', { method: loginMethod, user_id: user.id })

		const data = fbPixelUtils.normalizeUserData(user)
		facebookAction('CompleteRegistration', data)
	})

	eventer.listen('user:signin', ({ loginMethod, user }) => {
		trackers.gtag('event', 'login', { method: loginMethod, user_id: user.id })
	})

	eventer.listen('cart:item-added', ({ product, qty, order }) => {
		trackers.gtag('event', 'add_to_cart', gtagUtils.normalizeCartItemData(product, qty))

		const data = fbPixelUtils.normalizeOrderData(order)
		facebookAction('AddToCart', data)
	})

	eventer.listen('cart:item-removed', async ({ product, qty }) => {
		trackers.gtag('event', 'remove_from_cart', gtagUtils.normalizeCartItemData(product, qty))
	})

	eventer.listen('checkout:init', async ({ order }) => {
		trackers.gtag('event', 'begin_checkout', gtagUtils.normalizeOrderData(order))

		const data = fbPixelUtils.normalizeOrderData(order)
		facebookAction('InitiateCheckout', data)
	})

	eventer.listen('checkout:step-submit', async ({ stepKey, order }) => {
		if (stepKey === 'delivery') {
			trackers.gtag('event', 'add_shipping_info', gtagUtils.normalizeOrderData(order))
		} else if (stepKey === 'payment') {
			trackers.gtag('event', 'add_payment_info', gtagUtils.normalizeOrderData(order))
		} else if (stepKey === 'contact') {
			const data = fbPixelUtils.normalizeOrderData(order)
			facebookAction('AddPaymentInfo', data)
		} else if (stepKey === 'confirm') {
			let gtagPromise = new Promise((resolve) => {
				if (!trackers.gtagEnabled) return resolve()
				try {
					let params = gtagUtils.normalizeOrderData(order)
					params.event_callback = resolve
					trackers.gtag('event', 'purchase', params)
					setTimeout(resolve, 5000)
				} catch (err) {
					resolve()
				}
			})
			let fbqPromise = new Promise((resolve) => {
				if (!trackers.fbqEnabled) return resolve()
				try {
					const data = fbPixelUtils.normalizeOrderData(order)
					facebookAction('Purchase', data)
					setTimeout(resolve, 1000)
				} catch (err) {
					resolve()
				}
			})
			await Promise.all([gtagPromise, fbqPromise])
		}
	})

	eventer.listen('search:view-results', ({ products, searchTerm, listId }) => {
		const productsData = products.map((product, i) => ({
			product: product,
			variant: product.selectedVariant,
			index: 'index' in product ? product.index : i,
		}))
		trackers.gtag('event', 'view_search_results', {
			...gtagUtils.normalizeProductListData(productsData, listId),
			search_term: searchTerm,
		})
	})

	eventer.listen('search:search', ({ searchTerm }) => {
		trackers.gtag('event', 'search', {
			search_term: searchTerm,
		})
		const data = {
			search_string: searchTerm,
		}

		facebookAction('Search', data)
	})
}

function generateUUID(userId) {
	// Generate 16 random bytes
	const arr = new Uint8Array(16)
	window.crypto.getRandomValues(arr)

	// Convert the bytes to a UUID string format
	arr[6] = (arr[6] & 0x0f) | 0x40 // Version 4
	arr[8] = (arr[8] & 0x3f) | 0x80 // Variant 10xx

	let res = Array.from(arr)
		.map((b) => {
			const hex = b.toString(16).padStart(2, '0')
			return hex
		})
		.join('')
		.replace(/^(.{8})(.{4})(.{4})(.{4})(.{12})$/, '$1-$2-$3-$4-$5')

	res = `${res}-${Date.now()}`
	if (userId) {
		res += `-${userId}`
	}
	return res
}

