var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:_vm.$bem('product-data-sheet', 'secondary no-mobile-px py-0')},[(_vm.$b.d)?_c('v-row',{staticClass:"my-0",class:_vm.$bem('__product-view')},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:_vm.$bem('__image-cont'),on:{"mouseover":function($event){_vm.firstImageHover = true}}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1"}}),(_vm.secondImage && _vm.firstImageHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%"}})],1):_vm._e()],1)]}}],null,false,3058184333)})],1),_c('v-col',{staticClass:"py-15 d-flex flex-column justify-space-around",staticStyle:{"position":"relative"},attrs:{"cols":"8"}},[_c('h3',{staticClass:"font-3 header"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"d-flex align-center"},[_c('div',[(_vm.discountPct)?_c('div',{staticClass:"d-flex align-baseline mt-2 font-2"},[_c('del',{staticClass:"grey--text"},[_c('PriceText',{class:_vm.$bem('__prev-price'),attrs:{"amount":_vm.prevPrice,"currency":_vm.currency,"currencyMargin":0,"priceClass":"grey--text mr-2"}})],1),_c('span',{staticClass:"discount font-weight-medium mx-1",class:_vm.$bem('__discount')},[_vm._v(" "+_vm._s(_vm.discountPct)+"% OFF ")])]):_vm._e(),_c('PriceText',{staticClass:"price font-7 mb-5",class:_vm.$bem('__price'),attrs:{"amount":_vm.price,"currency":_vm.currency}})],1),_c('CollectionEventIcon',{staticClass:"ml-4",class:[_vm.$bem('__event-icon'), { 'mb-5': !_vm.discountPct }],attrs:{"multiple":"","product":_vm.product}})],1),(_vm.product.info.description)?_c('div',[_vm._v(_vm._s(_vm.product.info.description))]):_vm._e(),(_vm.hasTextTag)?_c('div',[_c('div',{class:[
						_vm.$bem('__text-tag', 'line-clamp-2 font-weight-medium primary px-2 py-1 rounded mt-2'),
						{ 'mb-2': _vm.financingTagHtml } ]},[_vm._v(" "+_vm._s(_vm.textTag)+" ")])]):_vm._e(),(_vm.financingTagHtml)?_c('div',[_c('div',{class:[
						_vm.$bem('__financing', 'line-clamp-2 font-weight-medium primary px-2 py-1 rounded'),
						{ 'my-2': !_vm.hasTextTag } ],domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}})]):_vm._e(),_c('ProductActionsSmall',{attrs:{"product":_vm.product,"variant":_vm.selectedVariant}})],1)],1):_c('div',{class:_vm.$bem('__product-view', 'd-flex flex-column align-center')},[_c('div',{class:_vm.$bem('__imgs-container', 'mb-2')},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
					var hover = ref.hover;
return [_c('div',{class:_vm.$bem('__image-cont'),on:{"mouseover":function($event){_vm.firstImageHover = true}}},[_c('Media',{class:_vm.$bem('__main-image'),attrs:{"cover":"","src":_vm.mainImage,"width":"100%","aspect-ratio":"1","imgClass":{ smallSize: _vm.$b.mt }}}),(_vm.secondImage && _vm.firstImageHover)?_c('div',{class:_vm.$bem('__second-image-cont')},[_c('Media',{class:[_vm.$bem('__second-image'), hover ? _vm.$bem('__second-image--show') : ''],attrs:{"cover":"","src":_vm.secondImage,"width":"100%","imgClass":{ smallSize: _vm.$b.mt }}})],1):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"d-flex flex-column justify-space-around w100"},[_c('h3',{staticClass:"font-2 header line-clamp-1"},[_vm._v(_vm._s(_vm.product.name))]),_c('div',{staticClass:"d-flex align-center"},[_c('div',[(_vm.discountPct)?_c('div',{staticClass:"d-flex align-baseline mt-2 font-1"},[_c('del',{staticClass:"grey--text"},[_c('PriceText',{class:_vm.$bem('__prev-price'),attrs:{"amount":_vm.prevPrice,"currency":_vm.currency,"currencyMargin":0,"priceClass":"grey--text mr-2"}})],1),_c('span',{staticClass:"discount font-weight-medium mx-2",class:_vm.$bem('__discount')},[_vm._v(" "+_vm._s(_vm.discountPct)+"% OFF ")])]):_vm._e(),_c('PriceText',{staticClass:"price font-6",class:[_vm.$bem('__price'), _vm.discountPct ? 'mb-3' : 'mt-3'],attrs:{"amount":_vm.price,"currency":_vm.currency}})],1),_c('CollectionEventIcon',{staticClass:"ml-4",class:[_vm.$bem('__event-icon'), { 'mt-3': !_vm.discountPct }],attrs:{"multiple":"","limitAmount":3,"product":_vm.product}})],1),(_vm.hasTextTag)?_c('div',[_c('div',{class:[
						_vm.$bem(
							'__text-tag-mobile',
							'font-weight-medium primary px-2 py-1 rounded line-clamp-1 mt-1'
						),
						{ 'mb-1': _vm.financingTagHtml } ]},[_vm._v(" "+_vm._s(_vm.textTag)+" ")])]):_vm._e(),(_vm.financingTagHtml)?_c('div',[_c('div',{class:_vm.$bem(
							'__financing-mobile',
							'font-weight-medium primary px-2 py-1 rounded line-clamp-1 mb-1'
						),domProps:{"innerHTML":_vm._s(_vm.financingTagHtml)}})]):_vm._e(),_c('ProductActionsSmall',{staticClass:"mt-3",attrs:{"product":_vm.product,"variant":_vm.selectedVariant}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }