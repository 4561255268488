import Vue from 'vue'
import Router from 'vue-router'
import { sync as vuexRouterSync } from 'vuex-router-sync'

export default function createRouter(routesProviders, routerOptions, options) {
	let { ssr, srv, eventer } = options
	// Load routes
	let routes = routesProviders.flatMap((routesProvider) => routesProvider(options))
	routes.sort((a, b) => {
		if (a.position == 'last') return 1
		if (b.position == 'last') return -1
		if (a.position == 'first') return -1
		if (b.position == 'first') return 1
		return (a.position || 0) - (b.position || 0)
	})

	const router = new Router({
		mode: 'history',
		scrollBehavior: (to, from, savedPosition) => {
			if (to.params.scrollPosition == 'reset') return { x: 0, y: 0 }
			if (to.params.scrollPosition == 'keep') return false

			if (to.params.scrollPosition) {
				return to.params.scrollPosition
			}
			if (to.hash) return { selector: to.hash }
			return savedPosition || { x: 0, y: 0 }
		},
		...(routerOptions || {}),
		routes,
	})

	if (options.store) {
		vuexRouterSync(options.store, router)
	}

	router.beforeEach((to, from, next) => {
		if (to.path != '/' && to.path.endsWith('/')) {
			let fixedPath = to.path.replace(/\/+$/, '')
			if (ssr) {
				ssr.res.redirect(301, fixedPath)
				ssr.responseSent = true
				next(false)
			} else {
				next(fixedPath)
			}
		} else {
			next()
		}
	})

	// mobile reload
	/*let firstTime = true
	router.beforeResolve((to, from, next) => {
		if (ssr || srv('deviceType') != 'phone') {
			next()
		} else if (firstTime) {
			firstTime = false
			next()
		} else {
			let vm = to.matched[0]?.components.default
			if (vm?.mobileReload) {
				if (vm.mobileReload(options, to, from) === true) {
					window.location.href = to.fullPath
					return
				}
			}
			next()
		}
	})*/

	// resolve data
	options.resolvedData = {}
	router.beforeResolve(async (to, from, next) => {
		let vm = to.matched[0]?.components.default
		let resolveData = vm?.resolveData || vm?.extends?.resolveData

		if (resolveData) {
			try {
				let data = srv.extract('_resolvedData')
				let __nextArgs = []
				if (!data) {
					let __next = (...args) => {
						__nextArgs = args
					}
					data = (await resolveData(options, to, from, __next)) || {}
					if (ssr) ssr.serverData._resolvedData = data
				}
				options.resolvedData[to.name] = data
				next(...__nextArgs)
			} catch (err) {
				next(err)
			}
		} else {
			next()
		}
	})

	router.afterEach((to, from) => {
		eventer.trigger('route:change', { to, from })
	})

	options.router = router
	options.mixins.push()
}

Vue.use(Router)

Vue.mixin({
	async created() {
		if (this.$options.resolveData) {
			Object.assign(this, this.$root.$options.resolvedData[this.$route.name])
		}
	},
	watch: {
		$route() {
			if (this.$options.resolveData) {
				Object.assign(this, this.$root.$options.resolvedData[this.$route.name])
			}
		},
	},
})

