export default (props = {}) => ({
	props,
	data() {
		return {
			spoConfig: this.$srv('ShopProductOptions'),
		}
	},
	computed: {
		spoCollectionId() {
			return this.spoConfig?.collectionId
		},
		spoCollectionMatch() {
			if (!this.product || !this.spoCollectionId || !this.product.collectionsIds?.length) return false
			return this.product.collectionsIds.some((id) => id == this.spoCollectionId)
		},
		spoHideBuyBtn() {
			return this.spoCollectionMatch && this.spoConfig?.hideBuyBtn
		},
		spoHideDiscount() {
			return this.spoCollectionMatch && this.spoConfig?.hideDiscount
		},
		spoHidePrevPrice() {
			return this.spoCollectionMatch && this.spoConfig?.hidePrevPrice
		},
		spoHidePrice() {
			return this.spoCollectionMatch && this.spoConfig?.hidePrice
		},
		spoHideAllPrice() {
			return this.spoHideDiscount && this.spoHidePrevPrice && this.spoHidePrice
		},
		spoHideQtyInput() {
			return this.spoCollectionMatch && this.spoConfig?.hideQtyInput
		},
		hideCalculatedPrice() {
			return this.spoCollectionMatch && this.spoConfig?.hideCalculatedPrice
		},
	},
})
