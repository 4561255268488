<template>
	<v-container v-bind="$attrs" :fluid="fluid" style="max-width: 1200px !important">
		<slot></slot>
	</v-container>
</template>

<script>
export default {
	props: {
		fluid: {
			type: Boolean,
			default: true,
		},
	},
}
</script>
