import { views } from '@/v-shop/autoloader'

export default ({ srv }) => {
	return srv('dynamicPagesRoutes', []).map((data) => {
		let route = {
			path: data.path,
			meta: data.meta,
			component: views.DynamicPage,
		}

		if (data.meta.blank) {
			route.meta.appComponent = { render: (h) => h('router-view') }
		}

		return route
	})
}

