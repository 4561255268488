<script>
export default {
	props: {
		src: String,
		iframeAttrs: {
			type: Object,
			default: () => ({}),
		},
	},
}
</script>
<template>
	<RatioBox v-bind="$attrs">
		<iframe
			:src="src"
			v-bind="iframeAttrs"
			:width="iframeAttrs.width || '100%'"
			:height="iframeAttrs.height || '100%'"
			:frameborder="iframeAttrs.frameborder || 0"
		></iframe>
	</RatioBox>
</template>
