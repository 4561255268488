export default {
	beforeCreate() {
		this.__unwatch = null
	},
	methods: {
		shouldLogin(callback) {
			this.__unwatch?.()

			let user = this.$store.get('shop/user')
			if (user) {
				callback(user, false)
				return
			}

			this.$store.set('shop/loginDrawer', true)

			let unwatchUser = this.$store.watch(
				(state) => state.shop.user,
				(value) => {
					if (value) {
						this.__unwatch?.()
						this.$store.set('shop/loginDrawer', false)
						callback(value, true)
					}
				}
			)

			let unwatchDrawer = this.$store.watch(
				(state) => state.shop.loginDrawer,
				(newValue, oldValue) => {
					if (newValue === false && oldValue === true) {
						this.__unwatch?.()
					}
				}
			)

			this.__unwatch = () => {
				unwatchDrawer()
				unwatchUser()
				this.__unwatch = null
			}
		},
	},
	beforeDestroy() {
		this.__unwatch?.()
	},
}
