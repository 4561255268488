<script>
import SsrCarousel from 'vue-ssr-carousel'
import 'vue-ssr-carousel/index.css'
import modelBypassMixin from '@/utils/model-bypass-mixin'

export default {
	components: { SsrCarousel },
	mixins: [modelBypassMixin([Number], 0)],
	props: {
		showDots: Boolean,
		arrowsBgHalfRounded: Boolean,
		dotContainerFullWidthMobile: Boolean,
	},
	data() {
		return {
			isHovering: false,
		}
	},
	computed: {
		itemsLength() {
			return Math.round(this.$slots.default?.length / (this.$attrs.slidesPerPage || 1))
		},
	},
	methods: {
		activeDot(item) {
			if (item == this.iValue + 1) return 'carousel__dots-icon--active'
			else return ''
		},
		arrowsBgHalfRoundedClass(side) {
			if (this.$b.d) {
				if (this.arrowsBgHalfRounded) return `carousel__arrow--bgHalfRounded-${side}`
				return 'carousel__arrow--bg'
			} else {
				if (this.arrowsBgHalfRounded) return `carousel__arrow--bgHalfRounded-${side}-mobile`
				return 'carousel__arrow--bg-mobile'
			}
		},
	},
	watch: {
		iValue(value) {
			// fix vue-ssr-carousel mobile bug NaN index
			if (Number.isNaN(value)) {
				this.$refs.ssrCarousel.currentX = 0
				this.$refs.ssrCarousel.index = 0
				this.$refs.ssrCarousel.targetX = 0
				this.iValue = 0
				this.$emit('slideIndex', 0)
			} else {
				this.$emit('slideIndex', value)
			}
		},
		isHovering(value) {
			if (value) this.$refs.ssrCarousel.autoplayStop()
		},
	},
}
</script>

<template>
	<div>
		<ssr-carousel
			ref="ssrCarousel"
			:no-drag="$b.d"
			v-bind="$attrs"
			:show-dots="false"
			:class="$bem('carousel')"
			v-model="iValue"
			v-on="$listeners"
		>
			<slot />
			<template #back-arrow="{ disabled }">
				<Hover v-model="isHovering">
					<div v-bind:class="[$bem('__arrow'), arrowsBgHalfRoundedClass('left')]">
						<v-icon
							class="mdi mdi-chevron-left base--text"
							color="black"
							:class="$bem('__arrow-icon')"
						></v-icon>
					</div>
				</Hover>
			</template>
			<template #next-arrow="{ disabled }">
				<Hover v-model="isHovering">
					<div v-bind:class="[$bem('__arrow'), arrowsBgHalfRoundedClass('right')]">
						<v-icon
							class="mdi mdi-chevron-right base--text"
							color="black"
							:class="$bem('__arrow-icon')"
						></v-icon>
					</div>
				</Hover>
			</template>
		</ssr-carousel>
		<div class="w100 d-flex justify-center" v-if="showDots && itemsLength > 1">
			<div
				class="d-flex flex-wrap rounded-lg justify-center py-1 px-2"
				:class="[
					$bem('__dots'),
					{ 'pa-2': $b.m },
					{ 'carousel__dots--desktop': $b.td, 'w100 mx-2': dotContainerFullWidthMobile && $b.m },
				]"
			>
				<span
					v-for="item of itemsLength"
					:key="item"
					v-bind:class="[$bem('__dots-icon'), activeDot(item), { 'carousel__dots-icon--mobile': $b.m }]"
				></span>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
::v-deep .v-icon.v-icon::after {
	width: unset;
}

// ::v-deep .ssr-carousel-track {
// 	padding: 0 !important;
// }

.carousel {
	&__arrow {
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #ffffff;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);

		&--bgHalfRounded-left,
		&--bgHalfRounded-left-mobile {
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
		}

		&--bgHalfRounded-right,
		&--bgHalfRounded-right-mobile {
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
		}

		&--bgHalfRounded-left:active,
		&--bgHalfRounded-left:hover {
			box-shadow: 0 7px 16px 0 rgb(0 0 0 / 30%), 0 1px 3px 0 rgb(0 0 0 / 30%);
			border-top-right-radius: 50%;
			border-bottom-right-radius: 50%;
		}

		&--bgHalfRounded-right:active,
		&--bgHalfRounded-right:hover {
			box-shadow: 0 7px 16px 0 rgb(0 0 0 / 30%), 0 1px 3px 0 rgb(0 0 0 / 30%);
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
		}

		&--bg,
		&--bg-mobile {
			border-radius: 50%;
		}

		&--bg:active,
		&--bg:hover {
			box-shadow: 0 7px 16px 0 rgb(0 0 0 / 30%), 0 1px 3px 0 rgb(0 0 0 / 30%);
			border-radius: 50%;
		}
	}

	&__arrow-icon {
		font-size: 40px;
	}

	&__dots {
		background-color: rgba(255, 255, 255, 0.4);
	}

	&--desktop {
		width: max-content;
	}

	&__dots-icon {
		border-radius: 50%;
		width: 12px;
		height: 12px;
		background-color: var(--link);
		// border: 3px solid white;
		opacity: 0.3;
		margin: 3px;

		&--active {
			// background-color: white !important;
			// border: 2px solid #676767 !important;
			width: 32px;
			border-radius: 10px;
			opacity: 1;
		}

		&--mobile {
			margin: 2px;
			width: 12px;
			height: 12px;
		}
	}
}
</style>

