import { views } from '@/v-shop/autoloader'
import { makeRoutesAccountGuard } from './routes-account-guard'

export default (options) => {
	const beforeEnter = makeRoutesAccountGuard(options)
	return [
		{
			beforeEnter,
			path: '/user/account',
			name: 'user.account',
			component: views.UserAccount,
		},
		{
			beforeEnter,
			path: '/user/favorites',
			name: 'user.favorites',
			component: views.UserFavorites,
		},
		{
			beforeEnter: (to, from, next) => {
				const reviewsConfig = options.srv('reviewsConfig')
				if (!reviewsConfig?.enabled) {
					next({ name: 'home' })
				} else {
					beforeEnter(to, from, next)
				}
			},
			path: '/user/reviews',
			name: 'user.reviews',
			component: views.UserReviews,
		},
		{
			beforeEnter,
			path: '/user/orders',
			name: 'user.orders',
			component: views.UserOrders,
		},
		{
			beforeEnter,
			path: '/user/orders/:id',
			name: 'user.order-detail',
			component: views.UserOrderDetail,
		},
	]
}
