export default (srv, window) => {
	let id = srv('Analytics.fbpId')
	if (!id) return null
	let loadScript = (f, b, e, v, n, t, s) => {
		if (f.fbq) return
		n = f.fbq = function () {
			n.callMethod
				? n.callMethod.apply(n, arguments)
				: n.queue.push(arguments)
		}
		if (!f._fbq) f._fbq = n
		n.push = n
		n.loaded = !0
		n.version = '2.0'
		n.queue = []
		t = b.createElement(e)
		t.async = !0
		t.src = v
		b.body.appendChild(t)
	}
	loadScript(
		window,
		document,
		'script',
		'https://connect.facebook.net/en_US/fbevents.js'
	)
	window.fbq('init', id)
	return window.fbq
}
