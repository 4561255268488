<script>
import { get } from 'vuex-pathify'

export default {
	props: {
		useImage: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			config: this.$srv('orderTotalGoalProgressConfig'),
			loading: false,
		}
	},
	computed: {
		order: get('cart/order'),
		itemsTotal() {
			return (
				this.order?.items
					?.filter((item) => this.itemInCollection(item))
					.reduce((acc, item) => acc + item.total, 0) || 0
			)
		},
		progressValue() {
			const value = (this.itemsTotal * 100) / Number(this.config.orderTotalGoal)
			return value >= 100 ? 100 : Math.round(value)
		},
		caption() {
			const rest = Number(this.config.orderTotalGoal) - this.itemsTotal
			if (rest <= 0) {
				return this.config.successCaption
			} else {
				return this.config.caption.replace(/\[amount\]/g, this.$options.filters.price(rest.toFixed(2)))
			}
		},
		isFailed() {
			return this.order?.items?.some((item) => !this.itemInCollection(item))
		},
	},
	methods: {
		itemInCollection(item) {
			if (!this.config.collectionId) return true
			return item.cartData?.collectionsIds?.some((id) => id == this.config.collectionId)
		},
	},
}
</script>

<template>
	<div class="w100">
		<div v-if="config.errorText && isFailed" class="error--text pb-2 d-flex align-center font-0">
			<v-icon left color="error" small>mdi-close-circle</v-icon>
			<span>{{ config.errorText }}</span>
		</div>
		<div :style="{ opacity: isFailed ? '.5' : null }">
			<Media :src="config.image" v-if="useImage && config.image" />
			<div v-if="config.description" class="pb-2 grey--text">
				{{ config.description }}
			</div>

			<div v-if="caption && !isFailed" class="font-weight-bold pb-2">
				{{ caption }}
			</div>

			<v-progress-linear
				:background-color="config.progressBarBackgroundColor || 'grey lighten-2'"
				:color="config.progressBarColor || $vars.success"
				:value="progressValue"
				rounded
				height="25px"
				width="100%"
			>
				<strong :style="{ color: config.progressBarTextColor || $vars.ctatext }"
					>{{ progressValue }}%</strong
				>
			</v-progress-linear>
			<div class="d-flex pt-2 font-weight-bold">
				<div>{{ 0 | price({ decimalCount: 0 }) }}</div>
				<v-spacer />
				<div>{{ config.orderTotalGoal | price({ decimalCount: 0 }) }}</div>
			</div>
		</div>
	</div>
</template>
