<script>
export default {
	props: {
		title: String,
		subtitle: String,
		btn: Object,
		titleStyle: String,
		titleClass: [Object, String],
		titleMarginY: {
			type: String,
			default: 'my-3',
		},
	},
	computed: {
		hasBtn() {
			return this.btn?.link && this.btn?.linkText
		},
	},
}
</script>

<template>
	<!-- TITLE STYLE MODERN OR SHORT -->
	<v-row
		class="mx-1 justify-space-between"
		v-if="titleStyle != 'double'"
		:class="[
			$bem('__header'),
			{
				'mx-4': $b.m,
				'modern-row': titleStyle == 'modern',
				'mb-5': titleStyle == 'short',
			},
			titleClass,
			titleMarginY,
		]"
	>
		<h2
			class="header d-block"
			:class="{
				modern: titleStyle == 'modern',
				short: titleStyle == 'short',
				'font-4': $b.td,
				'font-3': $b.m,
			}"
		>
			{{ title }}
		</h2>

		<v-btn v-if="hasBtn" :to="btn.link" text small depressed class="px-0 header link--text">
			{{ btn.linkText }}
		</v-btn>

		<div
			v-if="subtitle"
			:class="{
				'text-center': titleStyle == 'short' || titleStyle == 'double',
				'mt-1': titleStyle == 'short',
			}"
		>
			<p class="mb-4 font-2 base--text">{{ subtitle }}</p>
		</div>
	</v-row>

	<!-- TITLE STYLE DOUBLE -->
	<div v-else>
		<v-row
			class="justify-center mx-1 my-1 align-center"
			:class="[($bem('__header'), { 'mx-4': $b.m, 'mb-4': !hasBtn })]"
		>
			<div class="col d-flex double-col pa-0 mr-2">
				<div class="double"></div>
				<div class="double"></div>
			</div>
			<h2
				class="header d-block"
				:class="{
					'font-4': $b.td,
					'font-3': $b.m,
				}"
			>
				{{ title }}
			</h2>
			<div class="col d-flex double-col pa-0 ml-2">
				<div class="double"></div>
				<div class="double"></div>
			</div>
		</v-row>
		<v-row class="justify-center align-center mx-1 mb-4 mt-0" v-if="hasBtn || subtitle">
			<v-btn v-if="hasBtn" :to="btn.link" text small depressed class="px-0 header link--text">
				{{ btn.linkText }}
			</v-btn>
			<div v-if="subtitle" class="text-center">
				<p class="mb-0 font-2 base--text">{{ subtitle }}</p>
			</div>
		</v-row>
	</div>
</template>

<style scoped>
.modern-row {
	border-bottom: 1px solid var(--primary);
}

@media (max-width: 599.98px) {
	.modern-row {
		flex-wrap: nowrap;
	}
}

h2.modern::after {
	content: '';
	border-bottom: 4px solid var(--headertext);
	position: relative;
	width: 102%;
	display: block;
	bottom: -1px;
}

h2.short {
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

h2.short::after {
	content: '';
	border-bottom: 3.4px solid var(--headertext);
	position: relative;
	width: 20%;
	display: block;
	bottom: -1px;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 6px;
}

.double-col {
	flex-direction: column;
}

.double {
	height: 1.4px;
	background: var(--headertext);
	margin: 1px 0;
}
</style>
