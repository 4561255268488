export default ({ store, apis }) => {
	const { shopApi } = apis
	store.watch(
		() => store.get('shop/user'),
		(user) => {
			if (user) {
				shopApi.get({
					url: '/catalog/wishlist/products-ids',
					onSuccess: ({ data }) => {
						store.set('shop/wishlistIds', data.productsIds)
					},
				})
			} else {
				store.set('shop/wishlistIds', [])
			}
		}
	)
}
