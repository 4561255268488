<script>
export default {
	inheritAttrs: false,
	props: {
		width: [String, Number],
		height: [String, Number],
		maxWidth: [String, Number],
		maxHeight: [String, Number],
		aspectRatio: {
			type: [String, Number],
			default: 0,
		},
	},
	computed: {
		cWidth() {
			return this.sizeVal(this.width) || '100%'
		},
		cMaxWidth() {
			return this.sizeVal(this.maxWidth) || (this.width == '100%' ? null : '100%')
		},
	},
	methods: {
		sizeVal(num) {
			if (!num) return null
			return isNaN(num) ? num : `${num}px`
		},
	},
}
</script>

<template>
	<div
		class="ratio-box"
		:style="{
			width: cWidth,
			height: sizeVal(height),
			maxWidth: cMaxWidth,
			maxHeight: sizeVal(maxHeight),
		}"
		v-on="$listeners"
		v-bind="$attrs"
	>
		<div
			class="ratio-box__sizer"
			v-if="!height && aspectRatio"
			:style="{ paddingBottom: `${100 / Number(aspectRatio)}%` }"
		></div>
		<div class="ratio-box__slot">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped>
.ratio-box {
	width: 100%;
	position: relative;
}
.ratio-box__sizer {
	z-index: -1;
	pointer-events: none;
}
.ratio-box__slot {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}
</style>
