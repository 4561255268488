import sortBy from 'lodash/sortBy'
import formatPrice from '@/v-shop/helpers/format-price'
import Vue from 'vue'

// mixinFor -- 'page' or 'card'
export default (mixinFor = 'card') => ({
	computed: {
		limitedTimeOffer() {
			let offer = this.$store.get('shop/limitedTimeOffer')
			if (this.timerProduct)
				return offer?.find((item) => this.product.collectionsIds.find((el) => item.collectionId == el))
			else return offer
		},
		productOffers() {
			return this.limitedTimeOffer?.filter((offer) => this.inCollection(offer.collectionId))
		},
		hasLimitedTimeOffer() {
			return !!this.productOffers?.length
		},
		limitedTimeOfferDate() {
			const date = this.limitedTimeOffer?.untilDate

			const fecha = new Date(date).toLocaleDateString('es-AR', {
				day: 'numeric',
				month: 'long',
				timeZone: 'UTC',
			})
			if (this.timerProduct) return `La oferta termina el ${fecha}`
			else return `Hasta el ${fecha}`
		},
		textTags() {
			let textTags = this.$srv('textTags', [])
			return textTags?.filter((icon) => this.inCollection(icon.collectionId))
		},
		hasTextTag() {
			return !!this.textTags?.length
		},
		eventIcons() {
			let eventIcon = this.$srv('collectionEventIcon', [])
			return eventIcon?.filter((icon) => this.inCollection(icon.collectionId))
		},
		hasEventIcon() {
			return !!this.eventIcons?.length
		},
		productRoute() {
			let route = {
				name: 'product',
				params: { urlName: this.product.urlName, id: this.product.id },
			}
			if (!this.product.hasUniqueVariant) {
				route.query = { variant: this.selectedVariant.id }
			}
			return route
		},
		selectedVariant() {
			return this.product.variants.find((variant) => variant.forDisplay)
		},
		prevPrice() {
			return this.selectedVariant.pvPrice.prevPrice
		},
		price() {
			return this.selectedVariant.pvPrice.price
		},
		discountPct() {
			return Vue.filter('discountPct')(this.selectedVariant.pvPrice.discountPct)
		},
		currency() {
			return this.selectedVariant.pvPrice.priceConfig.currency.sign
		},
		mainImage() {
			let img = this.product.images[0].square
			img.alt = this.product.name
			return img
		},
		secondImage() {
			let img = this.product.images[1]?.square
			if (img) img.alt = this.product.name
			return img
		},
		brand() {
			return this.product.brand?.name
		},
		financingTagHtml() {
			let tags = this.$srv('financingTags', [])
			tags = tags.filter((tag) => tag.show == 'both' || tag.show == mixinFor)
			if (!tags.length) return null
			// tags = sortBy(tags, (e) => -Number(e.installments))
			let matchedTag = tags.find((tag) => this.inCollection(tag.collectionId))
			if (!matchedTag) return null
			return this.formatFinancingTagHtml(matchedTag.text, matchedTag.installments)
		},
		discountsTagsTexts() {
			let tags = this.$srv('discountTags', [])
			// tags = sortBy(tags, (e) => -Number(e.discount))
			return tags
				.filter((tag) => this.inCollection(tag.collectionId))
				.map((matchedTag) => this.formatDiscountTagText(matchedTag.text, matchedTag.discount))
		},
		buttonText() {
			switch (this.selectedVariant.stock.availability) {
				case 'PreSale':
					return 'Preventa'
				case 'OutOfStock':
					return 'Sin stock'
				case 'InStock':
				case 'PreOrder':
				default:
					return 'Comprar'
			}
		},
		outOfStock() {
			return this.selectedVariant.stock.availability == 'OutOfStock'
		},
	},
	methods: {
		formatFinancingTagHtml(text, installments) {
			let price = this.selectedVariant.pvPrice.price
			let currency = this.selectedVariant.pvPrice.priceConfig.currency.sign
			if (text.includes('[installments]')) text = text.split('[installments]').join(installments)
			if (text.includes('[amount]'))
				text = text
					.split('[amount]')
					.join(
						'<span class="financing-tag-price"> ' +
							formatPrice(price / installments, { currency }) +
							' </span>'
					)

			return text
		},
		formatDiscountTagText(text, discount) {
			let price = this.selectedVariant.pvPrice.price
			let currency = this.selectedVariant.pvPrice.priceConfig.currency.sign
			let percentage = 100 - discount

			if (text.includes('[discount]') || text.includes('[amount]')) {
				text = text.split('[amount]').join(formatPrice((price / 100) * percentage, { currency }))
				text = text.split('[discount]').join(discount)
				return text
			}
			return text
		},
		inCollection(collectionId) {
			return !!this.product.collectionsIds?.some((id) => id == collectionId)
		},
	},
})
