<script>
import inputMixin from '@/vuetify-mixins/input-mixin'
export default {
	lang: 'shop',
	mixins: [inputMixin],
	props: {
		countryId: Number,
		label: String,
		countryPhones: Array,
	},
	model: {
		prop: 'countryId',
		event: 'updateModel',
	},
	data() {
		return {
			loading: false,
			countries: [],
			defaultCountryId: null,
		}
	},
	computed: {
		cLabel() {
			return this.label || this.$lang('Cód. País')
		},
	},
	methods: {
		async loadData() {
			const setupPhoneSelect = (countries) => {
				return countries.map((country) => ({
					text: `(+${country.phoneCode}) ${country.name}`,
					value: country.id,
				}))
			}

			if (this.countryPhones?.length) {
				this.countries = setupPhoneSelect(this.countryPhones)
				if (!this.countryId) {
					this.$emit('updateModel', this.countries[0])
				}
				return
			}

			await this.$shopApi.get({
				url: '/user/phone-countries',
				loading: (v) => (this.loading = v),
				onSuccess: ({ data }) => {
					this.countries = setupPhoneSelect(data.countries)
					if (!this.countryId && data.defaultCountryId) {
						this.$emit('updateModel', data.defaultCountryId)
					}
				},
				cache: true,
			})
		},
		onInput($event) {
			this.$emit('updateModel', $event)
		},
		focus() {
			this.$refs.selector.focus()
		},
	},
	created() {
		this.loadData()
	},
}
</script>

<template>
	<Autocomplete
		ref="selector"
		:label="cLabel"
		:placeholder="$lang('Buscar...')"
		@input="onInput"
		:items="countries"
		:multiple="false"
		:loading="loading"
		:no-data-text="$lang('No se han encontrado resultados...')"
		:hide-no-data="loading"
		:value="countryId"
		:error-messages="validationErrors"
		v-bind="$attrs"
	/>
</template>

