export const makeRoutesAccountGuard = ({ ssr, router, store }) => {
	return (to, from, next) => {
		if (!store.get('shop/user')) {
			if (ssr) {
				ssr.responseSent = true
				ssr.res.redirect(301, process.env.VUE_APP_BASE_URL)
				next(false)
			} else {
				store.set('shop/loginDrawer', true)
			}
		} else {
			next()
		}
	}
}

