<script>
import shopProductOptions from '@/v-shop/mixins/shop-product-options-mixin'
import hooksMixin from '@/v-shop/mixins/hooks-mixin'

export default {
	mixins: [shopProductOptions({ product: Object }), hooksMixin('ProductCard')],
	props: {
		currency: {
			type: String,
			default: '$',
		},
		prevPriceAmount: Number,
		priceAmount: Number,
		discountPct: Number,
		containerClass: [Array, String, Object],
		prevPriceClass: [Array, String, Object],
		priceClass: [Array, String, Object],
		discountClass: [Array, String, Object],
		prevPriceGroup: { type: Boolean, default: false },
		elementOrder: {
			type: Object,
			default() {
				return { price: 1, prevPrice: 2 }
			},
		},
		horizontalCard: {
			type: Boolean,
			default: false,
		},
		sq: {
			type: Boolean,
			default: false,
		},
	},
	cssVars: {
		selector: '.product-card',
	},
	computed: {
		priceHookZone() {
			if (this.sq) return `price-sq`
			if (this.horizontalCard) return `priceHorizontal`
			else return 'price'
		},
		prevPriceHookZone() {
			if (this.sq) return `prevPrice-sq`
			if (this.horizontalCard) return `prevPriceHorizontal`
			else return 'prevPrice'
		},
		discountHookZone() {
			if (this.sq) return `discount-sq`
			if (this.horizontalCard) return `discountHorizontal`
			else return 'discount'
		},
	},
}
</script>

<template>
	<div v-if="!spoHideAllPrice">
		<div v-if="prevPriceGroup">
			<hook :zone="priceHookZone" v-if="!spoHidePrice">
				<PriceText :amount="priceAmount" :currency="currency" :priceClass="priceClass" />
			</hook>

			<div v-if="prevPriceAmount" :class="containerClass">
				<hook :zone="prevPriceHookZone" v-if="!spoHidePrevPrice">
					<del class="grey--text">
						<PriceText
							:amount="prevPriceAmount"
							:currency="currency"
							:currencyMargin="0"
							:priceClass="prevPriceClass"
						/>
					</del>
				</hook>

				<hook :zone="discountHookZone" v-if="!spoHideDiscount">
					<ProductCardText
						:textClass="discountClass"
						:conditional="discountPct > 0"
						:text="`${discountPct}% OFF`"
					/>
				</hook>
			</div>
		</div>

		<div v-else class="price">
			<hook :zone="discountHookZone" v-if="!spoHideDiscount">
				<ProductCardText
					:textClass="discountClass"
					:conditional="discountPct > 0"
					:text="`${discountPct}% OFF`"
				/>
			</hook>

			<div :class="containerClass">
				<hook :zone="priceHookZone" :style="{ order: elementOrder.price }" v-if="!spoHidePrice">
					<PriceText :amount="priceAmount" :currency="currency" :priceClass="priceClass" />
				</hook>

				<hook
					:zone="prevPriceHookZone"
					v-if="prevPriceAmount && !spoHidePrevPrice"
					:style="{ order: elementOrder.prevPrice }"
				>
					<del class="grey--text">
						<PriceText
							:amount="prevPriceAmount"
							:currency="currency"
							:currencyMargin="0"
							:priceClass="prevPriceClass"
						/>
					</del>
				</hook>
			</div>
		</div>
	</div>
</template>

<style scoped>
.price:nth-child(1) {
	order: var(--elementOrder-1, 1);
}
.price:nth-child(2) {
	order: var(--elementOrder-2, 2);
}
</style>

