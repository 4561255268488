import Vue from 'vue'
import Vuetify from 'vuetify/lib'
// import colors from 'vuetify/lib/util/colors'
import { Breakpoint } from 'vuetify/lib/services/breakpoint/index'
import merge from 'lodash/merge'
import { createBreakpointUtil } from '@/vuetify-utils/breakpoint'

// import custom vuetify options
const importAll = (r) => r.keys().map((file) => r(file).default)
const customOptions = importAll(require.context('@/v-shop', true, /\/vuetify-options\.js$/))

// override Breakpoint prototype for SSR breakpoint calculation
// override breakpoint update in order to always call getClientWidth
const _breakpoint_update = Breakpoint.prototype.update
Breakpoint.prototype.update = function () {
	return _breakpoint_update.call(this, false)
}
// override breakpoint getClientWidth to always use _fixedClientWidth
Breakpoint.prototype.getClientWidth = function () {
	return this._fixedClientWidth
}
// server side breakpoints width limits
const th_by_device = { phone: 400, tablet: 800, desktop: 1280 }

Vue.use(Vuetify)
Vue.mixin({
	beforeCreate() {
		if (this.$parent?.$b) this.$b = this.$parent.$b
		else if (this.$vuetify.breakpoint) {
			this.$b = createBreakpointUtil(this.$vuetify.breakpoint)
		}
	},
})

export default (options) => {
	let { srv, ssr } = options

	const defaultOptions = {
		theme: {
			disable: true,
			// options: {
			// 	customProperties: true,
			// 	variations: false,
			// },
			// themes: {
			// 	light: {
			// 		...srv('siteColors', {}),
			// 		error: '#FF5252',
			// 		success: '#4CAF50',
			// 		warning: '#FFC107',
			// 		link: colors.blue.darken4,
			// 	},
			// },
		},
	}

	options.vuetify = new Vuetify(merge(defaultOptions, ...customOptions))

	// set _fixedClientWidth based on user agent device type
	options.vuetify.framework.breakpoint._fixedClientWidth =
		th_by_device[srv('deviceType')] || th_by_device.desktop

	// insert vuetify colors stylesheet from server side
	// client side overrides automatically
	// if (ssr) {
	// 	// push root mixin
	// 	options.mixins.push({
	// 		metaInfo() {
	// 			return {
	// 				style: [
	// 					{
	// 						vmid: 'vuetify',
	// 						id: 'vuetify-theme-stylesheet',
	// 						type: 'text/css',
	// 						nonce: 'undefined',
	// 						cssText: this.$vuetify.theme.generatedStyles,
	// 					},
	// 				],
	// 			}
	// 		},
	// 	})
	// }
}
