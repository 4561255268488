import Vue from 'vue'

function _resolveFetchKey(value, comp) {
	if (typeof value == 'function') {
		return value.apply(comp)
	} else {
		return value
	}
}

Vue.mixin({
	beforeCreate() {
		if (this.$options.fetch && this.$options.fetchKey) {
			if (this.$ssr) {
				this.$options.serverPrefetch = async function () {
					await this.$options.fetch.call(this)
					this.$ssr.serverData._fetch = this.$ssr.serverData._fetch || {}
					let fetchKey = _resolveFetchKey(this.$options.fetchKey, this)
					this.$ssr.serverData._fetch[fetchKey] = this.$data || {}
				}
			} else {
				this.$fetch = async () => {
					await this.$options.fetch.call(this)
					await this.$options.afterClientSideFetch?.call(this)
				}
			}
		}
	},
	async created() {
		if (this.$fetch) {
			let fetchKey = _resolveFetchKey(this.$options.fetchKey, this)
			let data = this.$srv.extract(`_fetch.${fetchKey}`)
			if (data) {
				Object.assign(this, data)
				await this.$options.afterClientSideFetch?.call(this)
			} else {
				await this.$fetch()
			}
		}
	},
})
