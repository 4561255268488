<script>
import { VTextField } from 'vuetify/lib'
import inputMixin from '@/vuetify-mixins/input-mixin'

export default {
	name: 'TextField',
	extends: VTextField,
	mixins: [inputMixin],
}
</script>
