import Vue from 'vue'

export default function (options) {
	const _data = {}
	const _classes = {}
	const globals = function (key, defaultValue) {
		if (!key) return
		if (key in _data) return _data[key]
		else return defaultValue
	}

	globals.set = function (key, value) {
		if (typeof key == 'string') {
			if (value === undefined) return
			_data[key] = value
		} else if (typeof key == 'object') {
			Object.assign(_data, key)
		}
	}

	globals.class = function (className, defaultValue) {
		return `${className} ${_classes[className] || defaultValue || ''}`.trim()
	}

	globals.class.set = function (className, value) {
		if (typeof className == 'string') {
			if (value === undefined) return
			_classes[className] = value
		} else if (typeof className == 'object') {
			Object.assign(_classes, className)
		}
	}

	options.globals = globals
}

function createBem(_globals) {
	let main = ''
	return (className, defaultValue) => {
		if (!className.startsWith('__')) {
			main = className
			return _globals.class(className, defaultValue)
		} else {
			return _globals.class(`${main}${className}`, defaultValue)
		}
	}
}

Vue.mixin({
	beforeCreate() {
		this.$g = this.$root.$options.globals
		this.$bem = createBem(this.$g)
	},
})
