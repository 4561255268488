import Vue from 'vue'

function _applyReplacements(str, replacements) {
	if (!replacements) return str

	if (typeof replacements == 'string' || typeof replacements == 'number') {
		replacements = { 1: replacements }
	} else if (Array.isArray(replacements)) {
		replacements = replacements.reduce((obj, value, i) => Object.assign(obj, { [i + 1]: value }), {})
	}
	for (let k in replacements) {
		// eslint-disable-next-line no-useless-escape
		str = str.replace(new RegExp(`\{${k}\}`, 'g'), replacements[k])
	}
	return str
}

function cleanText(str) {
	return str.replace(/\\n/g, '\n')
}

export default function createLang(defaultNamespaceScope, options) {
	const { srv } = options
	const _translations = srv('lang.translations', {})
	const _cachedTranslations = {}
	const langIso = srv('lang.iso')

	const areTranslationsLoaded = (cacheKey) => {
		return !!_cachedTranslations[cacheKey]
	}

	const addTranslations = (translations, cacheKey) => {
		if (cacheKey) {
			if (_cachedTranslations[cacheKey]) return
			_cachedTranslations[cacheKey] = true
		}
		for (let x in translations) {
			_translations[x] = (_translations[x] || []).concat(translations[x])
		}
	}

	const translate = (namespace, value, replacements) => {
		let item = _translations[namespace]
			? _translations[namespace].find(([literal]) => literal == value)
			: null
		let str = (item && item[1]) || value || ''
		return _applyReplacements(str, replacements)
	}

	const translateText = (namespace, value, replacements) => {
		return translate(namespace, cleanText(value), replacements)
	}

	const $lang = translate
	const $langText = translateText

	options.i18n = {
		langIso,
		areTranslationsLoaded,
		addTranslations,
		translate,
		translateText,
		$lang,
		$langText,
		defaultNamespaceScope,
	}
}

Vue.mixin({
	beforeCreate() {
		const i18n = this.$options.i18n || this.$root.$options.i18n
		if (!i18n) return

		let { langIso } = i18n
		this.$langIso = langIso

		if (!this.$options.lang || typeof this.$options.lang != 'string') return

		const { areTranslationsLoaded, addTranslations, translate, translateText, defaultNamespaceScope } = i18n

		const full = (namespace) => {
			return namespace.match(/^[a-z]+\|/) ? namespace : `${defaultNamespaceScope}|${namespace}`
		}

		const namespace = full(this.$options.lang)

		this.$options.filters.lang = (value, ...args) => {
			if (typeof args[0] == 'string') {
				return translate(full(args[0]), value, args[1])
			} else {
				return translate(namespace, value, args[0])
			}
		}

		this.$lang = function (...args) {
			switch (args.length) {
				case 1:
					return translate(namespace, args[0], null)
				case 2:
					if (typeof args[1] == 'string') return translate(full(args[0]), args[1], null)
					else return translate(namespace, args[0], args[1])
				case 3:
					return translate(full(args[0]), args[1], args[2])
				default:
					return ''
			}
		}

		Object.assign(this.$lang, {
			langIso,
			areTranslationsLoaded,
			addTranslations,
			translate,
			translateText,
		})

		this.$langText = function (...args) {
			switch (args.length) {
				case 1:
					return translateText(namespace, args[0], null)
				case 2:
					if (typeof args[1] == 'string') return translateText(full(args[0]), args[1], null)
					else return translateText(namespace, args[0], args[1])
				case 3:
					return translateText(full(args[0]), args[1], args[2])
				default:
					return ''
			}
		}
	},
})
