export default (pluginsRunners, options) => {
	pluginsRunners.sort((a, b) => {
		return (b.priority || 0) - (a.priority || 0)
	})

	for (let runner of pluginsRunners) {
		if (runner) {
			if (typeof runner == 'function') {
				runner(options)
			} else {
				runner.run(options)
			}
		}
	}
}
