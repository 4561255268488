<script>
export default {
	props: {
		product: {
			type: Object,
			required: true,
		},
	},
	computed: {
		reviewsEnabled() {
			const reviewsConfig = this.$srv('reviewsConfig')
			if (reviewsConfig?.enabled) return reviewsConfig.showInProductCard
			else return false
		},
	},
}
</script>

<template>
	<div v-if="reviewsEnabled && product.ratingCount > 0" class="mb-2 d-flex align-center">
		<v-rating
			dense
			:value="product.ratingAvg"
			:color="$vars.strongtext"
			background-color="#cececd"
			size="20"
			readonly
		></v-rating>
		<p class="mb-0 ml-1 d-inline-block link--text">({{ product.ratingCount }})</p>
	</div>
</template>
