<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside'],
	showHook(currentZone, ProductPage) {
		return ProductPage.product.info.templateKey == 'descriptionAndAttrs'
	},
	computed: {
		show() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
	},
}
</script>

<template>
	<div v-if="show" class="mx-n3 px-3" :class="$b.m && 'bordered'">
		<ProductPage-Description :templateData="templateData" :product="ProductPage.product" class="pa-3" />
		<ProductPage-Attributes :attributes="attrs" class="pa-3" />
	</div>
</template>

<style lang="scss" scoped>
.bordered {
	border-top: 1px solid #eee;
}
</style>
