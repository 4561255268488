import Vue from 'vue'

export default function createCssVars(options) {
	const _vars = {}
	const _created = []

	const appendStyleTag = (cssSelector, vars) => {
		let varsStr = Object.entries(vars)
			.map(([k, v]) => `--${k}: ${v};`)
			.join('')
		let css = `${cssSelector}{${varsStr}}`
		if (options.ssr) {
			options.ssr.headTags.push({ html: `<style css-vars="${cssSelector}">${css}</style>` })
		} else {
			if (document.head.querySelector(`[css-vars="${cssSelector}"]`)) return
			let style = document.createElement('style')
			style.setAttribute('css-vars', cssSelector)
			style.appendChild(document.createTextNode(css))
			document.head.appendChild(style)
		}
	}

	options.createCssVars = function (cssSelector, defaultVars) {
		let vars = Object.assign({}, defaultVars || {}, _vars[cssSelector] || {})

		if (!_created.includes(cssSelector) && Object.keys(vars).length) {
			_created.push(cssSelector)
			appendStyleTag(cssSelector, vars)
		}

		return vars
	}

	options.setCssVars = function (cssSelector, vars) {
		_vars[cssSelector] = Object.assign(_vars[cssSelector] || {}, vars)
	}
}

Vue.mixin({
	created() {
		let vars = null
		if (this.$options.cssVars?.selector) {
			let { selector, ...defaultVars } = this.$options.cssVars
			vars = this.$root.$options.createCssVars?.(selector, defaultVars)
		} else if (this.$options.cssVars === true && this.selector) {
			vars = this.$root.$options.createCssVars?.(this.selector, this.defaultVars)
		}

		this.$vars = Object.assign({}, this.$parent?.$vars || {}, vars || {})
	},
})

Vue.component('css-vars', {
	cssVars: true,
	props: {
		selector: String,
		defaultVars: Object,
	},
	render(h) {
		return this.$scopedSlots?.default?.(this.$vars) || this.$slots.default
	},
})
