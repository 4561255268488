export default {
	install: (Vue) => {
		Vue.prototype.$setDeep = function (obj, x, val, merge = false) {
			let _isArray = Array.isArray(obj)
			let _apply = (val) => {
				_isArray ? obj.splice(x, 0, val) : this.$set(obj, x, val)
			}
			if (Array.isArray(val)) {
				_apply([])
				for (var i = 0; i < val.length; i++) {
					this.$setDeep(obj[x], i, val[i], merge)
				}
			} else if (typeof val === 'object' && val) {
				if (!merge || !obj[x] || Array.isArray(obj[x]) || typeof obj[x] != 'object') {
					_apply({})
				}
				for (let i in val) {
					this.$setDeep(obj[x], i, val[i], merge)
				}
			} else {
				_apply(val)
			}
		}
		Vue.prototype.$setArrayDeep = function (arr, arr2) {
			arr.splice(0, arr.length)
			for (var i = 0; i < arr2.length; i++) {
				this.$setDeep(arr, i, arr2[i])
			}
		}
		Vue.prototype.$assign = function (obj, ...obj2) {
			for (let o of obj2) {
				for (let x in o) {
					this.$set(obj, x, o[x])
				}
			}
			return obj
		}
		Vue.prototype.$assignDeep = function (obj, obj2, merge) {
			for (let x in obj2) {
				this.$setDeep(obj, x, obj2[x], merge)
			}
			return obj
		}
		Vue.prototype.$assignDefaults = function (obj, obj2) {
			for (let x in obj2) {
				this.$set(obj, x, obj[x] === undefined || obj[x] === null ? obj2[x] : obj[x])
			}
			return obj
		}
	},
}
