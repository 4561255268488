const getterKeys = [
	'xs',
	'sm',
	'md',
	'lg',
	'xl',
	'xsOnly',
	'smOnly',
	'smAndDown',
	'smAndUp',
	'mdOnly',
	'mdAndDown',
	'mdAndUp',
	'lgOnly',
	'lgAndDown',
	'lgAndUp',
	'xlOnly',
]
const alias = { m: 'xs', t: 'sm', d: 'mdAndUp', mt: 'smAndDown', td: 'smAndUp' }

function breakpointValue(breakpoint, value) {
	if (typeof value !== 'object' || !value) {
		return value
	} else {
		let { _, ...bpValues } = value
		let resolvedValue = _
		for (let bpkey in bpValues) {
			if (breakpoint[alias[bpkey] || bpkey]) resolvedValue = bpValues[bpkey]
		}
		return resolvedValue
	}
}
function breakpointProps(breakpoint, props) {
	let resolvedProps = {}
	for (let x in props) {
		resolvedProps[x] = breakpointValue(breakpoint, props[x])
	}
	return resolvedProps
}

function createBreakpointUtil(breakpoint) {
	let $b = (value) => breakpointValue(breakpoint, value)
	$b.props = (props) => breakpointProps(breakpoint, props)
	Object.assign(
		$b,
		getterKeys.reduce((obj, key) => {
			return Object.assign(obj, {
				get [key]() {
					return breakpoint[key]
				},
			})
		}, {})
	)
	Object.assign(
		$b,
		Object.keys(alias).reduce((obj, key) => {
			return Object.assign(obj, {
				get [key]() {
					return breakpoint[alias[key]]
				},
			})
		}, {})
	)
	return $b
}

export { createBreakpointUtil }
