<script>
export default {
	data() {
		return {
			value: false,
			opt: {},
			dialogRendered: false,
			dialogId: 0,
		}
	},
	computed: {
		hasContent() {
			return (
				this.opt.text || this.opt.htmlText || this.opt.code || this.opt.description || this.$slots.default
			)
		},
		actionsAlignClass() {
			return this.opt.actionsRight ? 'justify-end' : 'justify-center'
		},
	},
	methods: {
		open({
			type = 'success',
			title,
			text,
			htmlText,
			description,
			code,
			accept,
			cancel,
			onAccept,
			onCancel,
			maxHeight,
			maxWidth = 650,
			maxContentHeight = 650,
			actionsRight,
			loading = false,
			dialogId,
		} = {}) {
			this.opt = {}
			this.setOptions({
				type,
				title,
				text,
				htmlText,
				description,
				code,
				accept,
				cancel,
				onAccept,
				onCancel,
				maxHeight,
				maxWidth,
				maxContentHeight,
				actionsRight,
				loading,
				dialogId,
			})

			if (!this.dialogRendered) {
				this.dialogRendered = true
				this.$nextTick(() => {
					setTimeout(() => {
						this.value = true
					}, 100)
				})
			} else {
				this.value = true
			}
			this.dialogId += 1
			return this.dialogId
		},
		setOptions(options) {
			this.$assign(this.opt, options)
			if (!this.opt.title) {
				if (this.opt.type == 'error') this.opt.title = 'Ups!'
				else if (this.opt.type == 'success') this.opt.title = 'Listo!'
			}
			if (this.opt.cancel !== false && (this.opt.onAccept || this.opt.onCancel)) {
				this.opt.cancel = 'Cancelar'
			}
			if (!this.opt.accept) this.opt.accept = 'Aceptar'
		},
		close(dialogId = null) {
			if (dialogId) {
				if ((this.opt.dialogId && this.opt.dialogId == dialogId) || this.dialogId == dialogId) {
					this.value = false
				}
			} else {
				this.value = false
			}
		},
		cancel() {
			this.value = false
			this.opt.onCancel?.()
		},
		accept() {
			this.value = false
			this.opt.onAccept?.()
		},
	},
}
</script>

<template>
	<ClientSideOnly>
		<v-dialog
			v-if="dialogRendered"
			:value="value"
			:max-width="opt.maxWidth"
			persistent
			scrollable
			style="z-index: 9999999"
		>
			<v-card style="z-index: 9999999">
				<v-card-title class="headline" v-if="opt.title">
					{{ opt.title }}
				</v-card-title>
				<v-card-text v-if="hasContent" :style="{ maxHeight: `${opt.maxContentHeight}px` }" class="font-1">
					<slot>
						<div v-if="opt.text" :class="{ 'pt-8': !opt.title }" class="preline">
							{{ opt.text }}
						</div>
						<div v-else-if="opt.htmlText" :class="{ 'pt-8': !opt.title }" v-html="opt.htmlText" />

						<div v-if="opt.code" class="grey--text font-0">[{{ opt.code }}]</div>
						<div v-if="opt.description" class="pt-4">
							<pre style="white-space: pre-wrap">{{ opt.description }}</pre>
						</div>
					</slot>
				</v-card-text>
				<div v-if="opt.loading" class="pt-2">
					<v-progress-linear color="link" indeterminate height="6" />
				</div>
				<v-card-actions :class="actionsAlignClass" v-if="!opt.loading">
					<v-btn color="" text @click.stop="cancel()" v-if="opt.cancel">
						{{ opt.cancel }}
					</v-btn>
					<v-btn color="success" text @click.stop="accept()">
						{{ opt.accept }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</ClientSideOnly>
</template>

<style scoped>
::v-deep .v-dialog__content {
	z-index: 999999 !important;
}
</style>

