<script>
export default {
	name: 'ProductsLayout',
	props: {
		products: Array,
		loading: Boolean,
		listId: String,
		cardsPerLine: {
			type: [Number, Object],
			default: 3,
		},
		triggerListView: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			page: 1,
			mobilePCardLayout: this.$srv('mobilePCardLayout', 'horizontal'),
			mobileBtn: this.$srv('productCardType.btnMobile', 'noBtn') == 'noBtn' ? false : true,
		}
	},
	computed: {
		colSize() {
			switch (this.cardsPerLine) {
				case 2:
					return 6
				case 3:
					return 4
				case 4:
					return 3
				case 6:
					return 2
			}
			return 4
		},
		verticalCards() {
			if (this.$b.m) {
				if (this.mobilePCardLayout == 'vertical') return true
				else return false
			} else return true
		},
		mobileVerticalLayout() {
			return this.$b.m && this.mobilePCardLayout == 'vertical'
		},
	},
	mounted() {
		if (this.products.length && this.listId && this.triggerListView) {
			this.$eventer().trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
}
</script>

<template>
	<v-row v-if="loading">
		<v-col cols="12" :sm="colSize" class="mb-10" v-for="i of Array(12 / colSize).keys()" :key="i">
			<v-skeleton-loader type="card,paragraph" loading />
		</v-col>
	</v-row>
	<v-row v-else>
		<v-col
			:cols="mobileVerticalLayout ? 6 : 12"
			:sm="colSize"
			class="d-flex justify-center"
			v-for="(product, i) in products"
			:key="product.id"
			:class="{ 'py-1': $b.m, 'my-2': mobileVerticalLayout }"
		>
			<ProductCard
				:product="product"
				:list-id="listId"
				:list-index="i"
				:verticalCard="verticalCards"
				:small="mobileVerticalLayout"
				:mobileBtn="mobileBtn"
				:forMobileShop="mobileVerticalLayout"
			/>
		</v-col>
	</v-row>
</template>
