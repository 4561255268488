<script>
export default {
	props: {
		videoAttrs: {
			type: Object,
			default: () => ({}),
		},
		src: {
			type: String,
			required: true,
		},
	},
}
</script>
<template>
	<RatioBox v-bind="$attrs">
		<video
			:src="src"
			:width="videoAttrs.width || '100%'"
			:height="videoAttrs.height || '100%'"
			v-bind="videoAttrs"
		></video>
	</RatioBox>
</template>
