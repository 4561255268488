import { views } from '@/v-shop/autoloader'

const meta = { appComponent: 'CheckoutApp', isCheckout: true }

export default () => [
	{
		path: '/checkout/init',
		name: 'checkout',
		component: views.Checkout,
		meta,
	},
	{
		path: '/checkout/:hash/:stepKey',
		name: 'checkout.step',
		component: views.Checkout,
		meta,
	},
]
