export default function (config, currentRoute) {
	const fns = {
		exact: (pattern) => currentRoute === pattern,
		pattern: (pattern) => currentRoute.startsWith(pattern),
		regex: (pattern) => new RegExp(pattern).test(currentRoute),
	}

	let someFn = (condition) => fns[condition.urlCondition]?.(condition.pattern)

	if (config.urlHidePatterns?.some(someFn)) return false
	if (config.showAll) return true
	return !!config.urlShowPatterns?.some(someFn)
}

