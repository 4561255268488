export default ({ router, store }) => {
	router.afterEach((to) => {
		if (to.meta && to.meta.appComponent) {
			store.set('shop/appComponent', to.meta.appComponent)
		} else {
			store.set('shop/appComponent', store.get('shop/defaultAppComponent'))
		}
		//next()
	})
}
