<script>
export default {
	props: {
		items: Array,
	},
	metaInfo() {
		const script = [
			{
				vmid: 'ldjson-schema-breadcrumbs',
				type: 'application/ld+json',
				json: {
					'@context': 'https://schema.org',
					'@type': 'BreadcrumbList',
					itemListElement: this.items.map((item, index) => {
						let elm = {
							'@type': 'ListItem',
							position: index + 1,
							name: item.text,
						}
						if (item.route) {
							elm.item = process.env.VUE_APP_BASE_URL + this.$router.resolve(item.route).href
						}
						return elm
					}),
				},
			},
		]
		return {
			script,
		}
	},
	computed: {
		breadcrumbs() {
			const breadcrumbs = [...this.items]
			breadcrumbs[breadcrumbs.length - 1].last = true
			return breadcrumbs
		},
	},
}
</script>

<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs">
			<template v-slot:divider>
				<v-icon>mdi-chevron-right</v-icon>
			</template>
			<template v-slot:item="{ item }">
				<router-link
					:to="item.last ? null : item.route"
					:is="item.last ? 'span' : 'router-link'"
					:style="item.last ? 'color: var(--basetext) !important' : null"
					class="font-1"
					>{{ item.text }}</router-link
				>
			</template>
		</v-breadcrumbs>
	</div>
</template>

<style lang="scss" scoped>
// .v-breadcrumbs {
// 	&::v-deep li:nth-child(even) {
// 		padding-right: 0;
// 		padding-left: 0;
// 	}
// }

.v-breadcrumbs {
	padding: 0;
	display: block;
}
.v-breadcrumbs ::v-deep a,
.v-breadcrumbs ::v-deep span,
.v-breadcrumbs ::v-deep .v-breadcrumbs__divider {
	display: inline;
	vertical-align: middle;
	transition: opacity 0.3s;
}
.v-breadcrumbs ::v-deep .v-breadcrumbs__divider {
	padding: 0;
}
.v-breadcrumbs ::v-deep a:not(:hover) {
	opacity: 0.6;
}
</style>
