<script>
import urlMatch from '@/utils/urlMatch'

export default {
	hookZone: ['bottom'],
	cssVars: {
		selector: '.smart-popup',
	},
	metaInfo() {
		const link = []
		this.popupConfigs?.forEach((config) => {
			if (config.bgImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgImage).src,
				})
			}
			if (this.$b.m && config.bgImageMobile) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgImageMobile).src,
				})
			}
			if (config.useBgFullImage && config.bgFullImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImage).src,
				})
			}
			if (config.useBgFullImage && this.$b.m && config.bgFullImageMobile) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImageMobile).src,
				})
			}
			if (config.useBgFullImage && config.bgFullImageBottom) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.bgFullImage).src,
				})
			}
			if (config.insideImage) {
				link.push({
					as: 'image',
					rel: 'preload',
					href: this.$media(config.insideImage).src,
				})
			}
		})
		return { link }
	},
	computed: {
		popupConfigs() {
			return this.$srv('popupConfig')
				?.filter((x) => urlMatch(x, this.$route.fullPath))
				.filter((x) => x.device == 'x' || this.$b[x.device])
		},
	},
}
</script>

<template>
	<div v-if="popupConfigs && popupConfigs.length">
		<css-vars
			v-for="(popup, i) of popupConfigs"
			:key="i"
			:default-vars="popup.cssVars"
			:selector="`.smart-popup-${popup.id}`"
		>
			<ClientSideOnly>
				<SmartPopupCenter
					style="z-index: 10"
					:popupConfig="popup"
					v-if="popup.position == 'smart-popup-center'"
				/>
				<SmartPopupBottom
					style="z-index: 10"
					:popupConfig="popup"
					v-else-if="popup.position == 'smart-popup-bottom'"
				/>
				<SmartPopupRight
					style="z-index: 10"
					:popupConfig="popup"
					v-else-if="popup.position == 'smart-popup-right'"
				/>
			</ClientSideOnly>
		</css-vars>
	</div>
</template>

