import Vue from 'vue'
import './ssr-hook'
import './fetch-hook'
import createRouter from './create-router'
import createSrv from './create-srv'
import createStore from './create-store'
import createEventer from './create-eventer'
import createGlobals from './create-globals'
import createCssVars from './css-vars'
import assignPlugin from './assign-plugin'
import runPlugins from './run-plugins'
import VueMeta from 'vue-meta'
import Api from './api'

Vue.config.productionTip = false
Vue.use(assignPlugin)
Vue.use(VueMeta, { refreshOnceOnNavigation: true })
Vue.use(Api)

export default function createApp({
	ssrContext,
	storesProviders,
	routesProviders,
	routerOptions,
	pluginsRunners,
	AppComponent,
	window,
	errorHandler,
} = {}) {
	const options = {}
	options.ssr = ssrContext
	options.window = window
	options.apis = {}
	options.mixins = []

	createGlobals(options)
	createEventer(options)
	createSrv(options)
	createCssVars(options)

	if (storesProviders) {
		createStore(storesProviders, options)
	}

	if (routesProviders) {
		createRouter(routesProviders, routerOptions, options)
	}

	if (pluginsRunners) {
		runPlugins(pluginsRunners, options)
	}

	// let _vuemixin = Vue.mixin;
	// Vue.mixin = (obj, key) => {
	// 	if(!_mixins[key]) _mixins[key] = true
	// 	return _vuemixin(obj)
	// }

	options.render = (h) => h(AppComponent)

	if (errorHandler) {
		Vue.config.errorHandler = function (err, vm, info) {
			errorHandler(err, vm, info, options)
		}
	}

	return new Vue(options)
}

