<script>
export default {
	props: {
		text: Boolean,
		icon: Boolean,
	},
	computed: {
		fontColorClass() {
			if (this.text || this.icon || !this.$attrs.color) return ''
			switch (this.$attrs.color) {
				default:
					return 'white--text'
			}
		},
	},
	inheritAttrs: false,
}
</script>
<template>
	<v-btn v-bind="{ ...$attrs, ...$props }" v-on="$listeners" :class="fontColorClass">
		<slot />
	</v-btn>
</template>

<style scoped>
.v-btn {
	text-transform: none;
}
</style>
