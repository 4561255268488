import { getComponents } from '../autoloader'

function filterByKey(hooksKey) {
	let comps = {}
	let allComps = getComponents()
	for (let x in allComps) {
		if (x.startsWith(`Hook-${hooksKey}-`)) {
			comps[x] = allComps[x]
		}
	}
	return comps
}

function getChildren(components, hooksKey, hookContainer, zoneName, h, defaultChildren) {
	let children = Object.entries(components)
		.map(([name, component]) => {
			return { position: 0, name, component }
		})
		.filter((x) => {
			let zone = x.component.hookZone || 'default'

			if (typeof zone == 'string' && zone != zoneName) return false
			if (Array.isArray(zone) && !zone.includes(zoneName)) return false

			if (x.component.showHook && !x.component.showHook(zoneName, hookContainer)) return false

			x.position = x.component.hookPosition || 0
			if (typeof x.position == 'object') {
				x.position = x.position[zoneName]
			}

			return true
		})
		.sort((a, b) => a.position - b.position)
		.map((x) => h(x.name, { props: { [hooksKey]: hookContainer, currentZone: zoneName } }))

	return children.length ? children : defaultChildren || []
}

function makeHookZoneComponent(hooksKey) {
	let components = filterByKey(hooksKey)

	return {
		components,
		props: {
			zone: {
				type: String,
				default: 'default',
			},
			wrapperTag: String,
		},
		inject: ['hookContainer'],
		render(h) {
			let { components } = this.$options
			let hookContainer = this.hookContainer()
			let children = []
				.concat(getChildren(components, hooksKey, hookContainer, `before--${this.zone}`, h))
				.concat(getChildren(components, hooksKey, hookContainer, this.zone, h, this.$slots.default))
				.concat(getChildren(components, hooksKey, hookContainer, `after--${this.zone}`, h))

			return children.length ? h(this.wrapperTag || 'div', children) : null
		},
	}
}

export default (hooksKey) => ({
	components: {
		hook: makeHookZoneComponent(hooksKey),
	},
	provide() {
		return {
			hookContainer: () => this,
		}
	},
})

