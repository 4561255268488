// Vue
import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'

Vue.use(Vuex)

export default function createStore(storesProviders, options) {
	// Modules
	let modules = storesProviders.reduce((obj, storeProvider) => {
		let storeModule = storeProvider(options)
		obj[storeModule.name] = storeModule
		return obj
	}, {})

	// pathify
	pathify.options.mapping = 'standard'
	pathify.options.strict = true

	options.store = new Vuex.Store({
		modules,
		plugins: [pathify.plugin],
	})
}
