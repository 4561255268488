import { views } from '@/v-shop/autoloader'

let productBaseUrl = process.env.VUE_APP_SHOP_PRODUCT_BASE_URL
let shopBaseUrl = process.env.VUE_APP_SHOP_BASE_URL
if (shopBaseUrl == '/') shopBaseUrl = ''

export default () => [
	{
		path: `${productBaseUrl}/:urlName/:id`,
		name: 'product',
		component: views.ProductPage,
	},
	{
		path: '/upsells/:id',
		name: 'upsells',
		component: views.ProductUpsells,
	},
	{
		path: `${shopBaseUrl}/:filters+`,
		name: 'shop',
		component: views.ShopList,
		position: 'last',
	},
	{
		path: '/arrepentimiento',
		name: 'repentance',
		component: views.RepentanceForm,
	},
]
