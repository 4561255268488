<script>
export default {
	props: {
		src: {
			type: String,
			required: true,
		},
		iconColor: {
			type: String,
		},
	},
}
</script>
<template>
	<RatioBox v-bind="$attrs">
		<div class="d-flex justify-center align-center h100">
			<v-icon large :color="iconColor"> mdi-file-pdf-box </v-icon>
		</div>
	</RatioBox>
</template>
