<script>
export default {
	props: ['currentZone', 'ProductPage'],
	hookZone: ['after--images', 'bottom-inside', 'bottom'],
	showHook(currentZone, ProductPage) {
		return ProductPage.product.info.templateKey == 'infoAndImages'
	},
	computed: {
		showDescriptionAndAttrs() {
			return (
				(this.$b.d && this.currentZone == 'after--images') ||
				(this.$b.mt && this.currentZone == 'bottom-inside')
			)
		},
		showInfoTabs() {
			return this.currentZone == 'bottom-inside'
		},
		templateData() {
			return this.ProductPage.product.info.templateData
		},
		attrs() {
			return this.ProductPage.product.attrs || []
		},
	},
}
</script>

<template>
	<div>
		<div class="mx-n3 px-3" style="border-top: 1px solid #eee" v-if="showDescriptionAndAttrs">
			<ProductPage-Description :templateData="templateData" :product="ProductPage.product" class="pa-3" />
			<ProductPage-Attributes :attributes="attrs" class="pa-3" />
		</div>
		<div class="mx-n3" style="border-top: 1px solid #eee" v-if="showInfoTabs">
			<ProductPage-InfoDropdownImages :data="templateData" />
		</div>
	</div>
</template>
