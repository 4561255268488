<script>
export default {
	props: {
		products: Array,
		listId: String,
		offer: Object,
		timerProduct: Boolean,
		title: String,
	},
	data() {
		return {
			currentIndex: 0,
		}
	},
	mounted() {
		if (this.products.length && this.listId) {
			this.$eventer().trigger('product-list:view', {
				products: this.products,
				listId: this.listId,
			})
		}
	},
	computed: {
		isFirstItem() {
			return this.currentIndex === 0
		},
		isLastItem() {
			return this.currentIndex === this.products.length - 1
		},
	},
	methods: {
		next() {
			if (this.currentIndex < this.products.length - 1) {
				this.currentIndex++
			}
		},
		prev() {
			if (this.currentIndex > 0) {
				this.currentIndex--
			}
		},
	},
}
</script>

<template>
	<div>
		<div class="d-flex justify-space-between">
			<div v-if="title" class="title font-3 line-clamp-1">
				{{ title }}
			</div>
			<div class="d-flex">
				<v-icon
					:color="$vars.primary"
					@click="prev"
					:style="isFirstItem && 'visibility: hidden'"
					class="mr-2"
					>mdi-chevron-left</v-icon
				>
				<v-icon :color="$vars.primary" @click="next" :style="isLastItem && 'visibility: hidden'"
					>mdi-chevron-right</v-icon
				>
			</div>
		</div>
		<div class="mt-10">
			<div
				v-for="(product, n) of products"
				:key="n"
				class="tab"
				:class="{ 'active-tab': n == currentIndex }"
			>
				<ProductCard
					v-if="currentIndex === n"
					class="w100"
					:product="product"
					:list-id="listId"
					:list-index="n"
					:offer="offer"
					featured
					:timerProduct="timerProduct"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.tab {
	transform: translateX(30px);
	opacity: 0;
	transition: all 0.3s ease-out;
}

.active-tab {
	opacity: 1;
	transform: translateX(0);
}
::v-deep {
	@for $i from 1 through 8 {
		.product-card-design#{$i}-with-timer-vertical {
			border-radius: 30px;
		}
	}
}
</style>
