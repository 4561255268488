<script>
export default {
	props: {
		textClass: [Array, String, Object],
		conditional: { type: [Boolean, String], default: true },
		text: String,
	},
}
</script>

<template>
	<div :class="textClass" v-if="conditional">
		{{ text }}
	</div>
</template>
