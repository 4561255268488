var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.titleStyle != 'double')?_c('v-row',{staticClass:"mx-1 justify-space-between",class:[
		_vm.$bem('__header'),
		{
			'mx-4': _vm.$b.m,
			'modern-row': _vm.titleStyle == 'modern',
			'mb-5': _vm.titleStyle == 'short',
		},
		_vm.titleClass,
		_vm.titleMarginY ]},[_c('h2',{staticClass:"header d-block",class:{
			modern: _vm.titleStyle == 'modern',
			short: _vm.titleStyle == 'short',
			'font-4': _vm.$b.td,
			'font-3': _vm.$b.m,
		}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.hasBtn)?_c('v-btn',{staticClass:"px-0 header link--text",attrs:{"to":_vm.btn.link,"text":"","small":"","depressed":""}},[_vm._v(" "+_vm._s(_vm.btn.linkText)+" ")]):_vm._e(),(_vm.subtitle)?_c('div',{class:{
			'text-center': _vm.titleStyle == 'short' || _vm.titleStyle == 'double',
			'mt-1': _vm.titleStyle == 'short',
		}},[_c('p',{staticClass:"mb-4 font-2 base--text"},[_vm._v(_vm._s(_vm.subtitle))])]):_vm._e()],1):_c('div',[_c('v-row',{staticClass:"justify-center mx-1 my-1 align-center",class:[(_vm.$bem('__header'), { 'mx-4': _vm.$b.m, 'mb-4': !_vm.hasBtn })]},[_c('div',{staticClass:"col d-flex double-col pa-0 mr-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})]),_c('h2',{staticClass:"header d-block",class:{
				'font-4': _vm.$b.td,
				'font-3': _vm.$b.m,
			}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"col d-flex double-col pa-0 ml-2"},[_c('div',{staticClass:"double"}),_c('div',{staticClass:"double"})])]),(_vm.hasBtn || _vm.subtitle)?_c('v-row',{staticClass:"justify-center align-center mx-1 mb-4 mt-0"},[(_vm.hasBtn)?_c('v-btn',{staticClass:"px-0 header link--text",attrs:{"to":_vm.btn.link,"text":"","small":"","depressed":""}},[_vm._v(" "+_vm._s(_vm.btn.linkText)+" ")]):_vm._e(),(_vm.subtitle)?_c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-0 font-2 base--text"},[_vm._v(_vm._s(_vm.subtitle))])]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }