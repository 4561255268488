import Api from '@/utils/api'

export default {
	priority: 10,
	run: (options) => {
		let { ssr } = options
		let baseUrl = `${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_SHOP_API_BASE_URL}`
		let renderApi = ssr?.renderApi
		const shopApi = new Api({ baseUrl, renderApi })
		options.apis.shopApi = shopApi
	},
}
