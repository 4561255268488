import createApp from './app'
import Vue from 'vue'
// import axios from 'axios'

/*const errorHandler = (err, vm, info, options) => {
	axios.post(options.apis.shopApi.makeUrl('/errlog'), { err: String(err.stack), info })
}*/

const app = createApp({ window })

app.$options.router.onReady(() => {
	app.$once('hook:mounted', () => {
		app.$nextTick(() => {
			setTimeout(() => {
				app._isHydrated = true
				app.$options.eventer.trigger('hydrated')
				for (let x in window.__USER_DATA__ || {}) {
					app.$store.set(x, window.__USER_DATA__[x])
				}
			}, 2000)
		})
	})
	app.$mount('#app', true)
})

