<script>
import shouldLoginMixin from '@/v-shop/mixins/should-login-mixin'
import { get } from 'vuex-pathify'
export default {
	mixins: [shouldLoginMixin],
	props: {
		productId: String,
		height: {
			type: [String, Number],
			default: 40,
		},
		width: {
			type: [String, Number],
			default: 40,
		},
		iconSize: {
			type: String,
			default: 'default',
		},
	},
	data() {
		return {
			loading: false,
		}
	},
	computed: {
		wishlist: get('shop/wishlistIds'),
		inWishlist() {
			return this.wishlist.includes(this.productId)
		},
		icon() {
			return this.inWishlist ? 'mdi-heart' : 'mdi-heart-outline'
		},
		buttonColor() {
			return {
				backgroundColor: this.inWishlist ? this.$vars.success : this.$vars.warning,
			}
		},
		iconColor() {
			return {
				color: this.inWishlist ? this.$vars.successtext : this.$vars.warningtext,
			}
		},
	},
	methods: {
		updateWishlist() {
			return this.$shopApi.get({
				url: '/catalog/wishlist/products-ids',
				onSuccess: ({ data }) => {
					this.$store.set('shop/wishlistIds', data.productsIds)
				},
			})
		},
		toggleWishlist() {
			this.shouldLogin(() => {
				console.log(this.productId)
				this.$shopApi.post({
					url: `/catalog/wishlist/${this.inWishlist ? 'remove' : 'add'}/${this.productId}`,
					loading: (v) => (this.loading = v),
					onSuccess: () => this.updateWishlist(),
				})
			})
		},
	},
}
</script>

<template>
	<Button
		@click="toggleWishlist"
		class="share-button"
		fab
		icon
		:height="height"
		:width="width"
		:style="buttonColor"
		:loading="loading"
	>
		<span :style="iconColor">
			<v-icon :size="iconSize">{{ icon }}</v-icon>
		</span>
	</Button>
</template>
