import urlMatch from '@/utils/urlMatch'
import moment from 'moment'
import { sync } from 'vuex-pathify'

export default {
	data() {
		return {
			currentDay: new Date().toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase(),
		}
	},
	methods: {
		agentIsOnSchedule(agent) {
			return agent.active && agent[this.currentDay] && this.agentIsOnScheduleHours(agent)
		},
		agentIsOnScheduleHours(agent) {
			const start = moment(agent[`${this.currentDay}ScheduleStart`], 'HH:mm')
			const end = moment(agent[`${this.currentDay}ScheduleEnd`], 'HH:mm')
			return moment().isBetween(start, end)
		},
	},
	computed: {
		showWhatsappChat: sync('shop/showWhatsappChat'),
		whatsAppConfigs() {
			return this.$srv('whatsAppBtnConfig', [])
				.filter((x) => urlMatch(x, this.$route.fullPath))
				.map((x) => ({
					...x,
					agents: x.agents?.filter((x) => this.agentIsOnSchedule(x)) || [],
				}))
				.filter((x) => x.agents.length)
		},
	},
}

