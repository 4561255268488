<script>
import { get } from 'vuex-pathify'

export default {
	computed: {
		routeLoading: get('shop/routeLoading'),
	},
}
</script>

<template>
	<transition :duration="{ enter: 300, leave: 1000 }">
		<div
			class="app-route-loading-bar elevation-1"
			v-show="routeLoading"
			:class="$b.td ? 'app-route-loading-bar--desktop' : 'app-route-loading-bar--mobile'"
		>
			<LoadingBar :loading="routeLoading" />
			<div class="text-center white--text font-2 px-2 py-2 font-weight-bold" v-if="$b.m">CARGANDO...</div>
		</div>
	</transition>
</template>

<style lang="scss" scoped>
.app-route-loading-bar {
	position: fixed;
	left: 0;
	z-index: 9999;
	width: 100%;
	opacity: 1;
	transform: translateY(0%);

	&.v-enter-active {
		transition: transform 0.3s;
	}
	&.v-leave-active {
		transition: opacity 1s;
	}

	&--desktop {
		top: 0;
		&.v-enter {
			transform: translateY(-100%);
		}
	}
	&--mobile {
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		&.v-enter {
			transform: translateY(100%);
		}
	}
	&.v-leave-to {
		opacity: 0;
	}
}
</style>
